<template>
    <div class="">
        <modal-tool-transfer-confirm :thisModal="modalNewToolTransferConfirm" modalId="modalNewToolTransferConfirm" title="Transfer" :toolTransfer="selectedToolTransfer" @confirmed="confirmedToolTransfer" />
        <h2>Transferi alata <span @click="refreshData()"><font-awesome-icon icon="sync" /></span></h2>
        
        <div>
            <ejs-grid
                ref="allToolTransfers"
                :dataSource="allToolTransfers"
                class="all-tool-transfers-grid"
                :allowFiltering='true'
                :allowSorting='true'
                :showColumnMenu='true'
                :filterSettings='filterSettings'
                :allowPaging='paginatorVissible' 
                :pageSettings='pageSettings'

                :allowSelection='false'
                :enableHover='true'
                :enableHeaderFocus='true'

                :recordClick="toolDBClick"
                :allowResizing='true'
                :allowTextWrap='true'

                :queryCellInfo='customiseCell'


                >
                <e-columns>
                    <e-column field='id' headerText='ID' width='120' textAlign='Right' :isPrimaryKey='true' :visible='false' ></e-column>

                    <e-column field='tool_obj.name' headerText='Naziv' width='120' textAlign='Left' :visible='true' :template="toolsNameTemplate"></e-column>
                    <e-column field='tool_obj.manufacturer' headerText='Proizvodjač' width='120' textAlign='Left' :visible='false'></e-column>
                    
                    <e-column field='from' headerText='Poseduje' width='150' textAlign='Left' :template="fromTemplate"></e-column>
                    <e-column field='to' headerText='Uzima' width='150' textAlign='Left' :template="toTemplate"></e-column>
                    <e-column field='transfer_status_display' headerText='Status' width='120' textAlign='Left'></e-column>
                    

                    <e-column field='approved_by.username' :valueAccessor='fullnameAccessor' headerText='Obradio' width='130' :visible='false'></e-column>

                    <e-column field='updated_at' :valueAccessor='dateTimeAccessor' headerText='Poslednja izmena' width='200' format="yMd HH:mm" textAlign='Right' :visible='true'></e-column>
                    <e-column field='modified_by.username' :valueAccessor='fullnameAccessor' headerText='Poslednji izmenio' width='130' :visible='false'></e-column>
                    <e-column field='created_at' :valueAccessor='dateTimeAccessor' headerText='Kreirano' width='200' format="yMd HH:mm" textAlign='Right' :visible='false'></e-column>
                    <e-column field='created_by.username' :valueAccessor='fullnameAccessor' headerText='Kreirao' width='130' :visible='false'></e-column>

                    <e-column headerText='' :visible='true' textAlign='Right' :template="toolTransfersCMD"></e-column>

                </e-columns>
            </ejs-grid>    
        </div>
    </div>
    
</template>


<script>

    import {mapState} from 'vuex'
    // import RequestStatus from '@/components/RequestStatus.vue';
    // import { Modal } from 'bootstrap';
    import UtilService from '@/service/UtilService.js';

    import ToolsService from '@/service/ToolsService.js';
    import { Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit, Resize } from "@syncfusion/ej2-vue-grids";
    import { Modal } from 'bootstrap'
    // import ToolTypesButtons from '@/components/tools/ToolTypesButtons.vue';
    // import ToolsWarehouseButtons from '@/components/tools/ToolsWarehouseButtons.vue';
    import ToolTransfersCMD from '@/components/grid-templates/ToolTransfersCMD.vue';
    import ModalToolTransferConfirm from '@/components/modals/tools/ModalToolTransferConfirm.vue';


    export default {
        components: {
            ModalToolTransferConfirm,
            // ToolTypesButtons,
            // ToolsWarehouseButtons,
        },
  
       data() {
         
            return {
                modalNewToolTransferConfirm: null,


                allTools: [],
                allToolTransfers: [],


                toolType: undefined,
                warehouse: undefined,
                selectedToolTransfer: undefined,
                intervalRefresh: undefined,
                refreshCount: 0,

                filterSettings: { type: "Excel" },
                pageSettings: { pageCount: 20},
                editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true },


                toolsNameTemplate: function() {
                    return {
                        template: {
                            extends: ToolTransfersCMD,
                            propsData: {
                                column: "TOOL_NAME"
                            },   
                        }
                    };
                },

                fromTemplate: function() {
                    return {
                        template: {
                            extends: ToolTransfersCMD,
                            propsData: {
                                column: "FROM"
                            },   
                        }
                    };
                },

                toTemplate: function() {
                    return {
                        template: {
                            extends: ToolTransfersCMD,
                            propsData: {
                                column: "TO"
                            },   
                        }
                    };
                },

                toolTransfersCMD: function() {
                    return {
                        template: {
                            extends: ToolTransfersCMD,
                            propsData: {
                                column: "CMD"
                            },
                            
                        }

                    };
                },


                
            }
        },

        provide: {
            grid: [Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit, Resize ]
        },

        computed: {
            ...mapState([
                'user',
            ]),

            isAdmin() {
                if (this.user.role === 'ADMIN') return true;
                return false;
            },

            isManager() {
                if (this.user.role === "MANAGER" || this.user.role === "ADMIN" || this.userAssignment?.pwo?.pwo_role == 1)
                    return true;
                return false;
            },

            paginatorVissible() {
                if (this.allToolTransfers?.length > 20) return true;
                return false;
            }
        },


        

        methods: {
            
            formatDate: UtilService.formatDate,
            formatDateTime: UtilService.formatDateTime,
        
            fullnameAccessor(field, data) {
                let firstField = field.split('.')[0];
                let userData = data?.[firstField];
                if (!userData?.first_name) return;
                return userData.first_name + " " + userData.last_name; 
            },

            dateTimeAccessor(field, data) {
                return data[field] ? this.formatDateTime(data[field]) : '/';
            },
            // async loadAllTransports() {
            //     this.loading = true;
            //     this.error = null;

            //     await this.$store.dispatch('loadAllTransportVehicles', this.workOrderForm).catch((error) => {
            //         this.error = error;
            //     });

            //     this.loading = false;
            // },

            customiseCell(args) {
                if (this.isTransferReadyForAction(args.data)) {
                    args.cell.classList.add('ready-action');
                }
            },

            loadAllPendingTransfers() {
                let query = {
                    transfer_status: ["IN_TRANSFER", "REQUESTED"],
                }

                ToolsService.getToolTransfer(query).then(async (result)=>{
                    this.changeTransferData(result.data)
                });
            },

            loadMyPendingTransfers() {
                let query = {
                    transfer_status: ["IN_TRANSFER", "REQUESTED"],
                    transfer_participates: "MANAGER",
                }

                ToolsService.getToolTransfer(query).then(async (result)=>{
                    this.changeTransferData(result.data)
                });
            },

            loadOnlyMinePendingTransfers() {
                let query = {
                    transfer_status: ["IN_TRANSFER", "REQUESTED"],
                    transfer_participates: "ME",
                }

                ToolsService.getToolTransfer(query).then(async (result)=>{
                    this.changeTransferData(result.data)
                });
            },

            changeTransferData(data) {

                let newData = ToolsService.prepareToolTransfersData(data);
                if (UtilService.isObjectsSame(this.allToolTransfers, newData)) return

                this.allToolTransfers = newData
                this.autoSelectRecent(this.allToolTransfers);
            },

            autoSelectRecent(transfers) {
                transfers.forEach(transfer => {
                    let updated_time = UtilService.intervalToSeconds(transfer.updated_at)
                    if (updated_time > 15) return;

                    if (this.isTransferReadyForAction(transfer)) this.showToolTransferModal(transfer);
                    
                    
                });
            },

            isTransferReadyForAction(transfer) {
                if (transfer.transfer_status === 'REQUESTED') {
                    if (transfer.from_user === this.user.id) return true;
                    if (transfer.from_user === null && this.isManager) return true
                }

                else if (transfer.transfer_status === 'IN_TRANSFER') {
                    if (transfer.to_user === this.user.id) return true;
                    if (transfer.to_user === null && this.isManager) return true
                }
            },

            async loadAllToolTypes() {
                await this.$store.dispatch('getAllToolTypes')
            },
            async loadAllWarehouses() {
                await this.$store.dispatch('getAllWarehouses')
            },

            loadAllWorkers() {
                this.$store.dispatch('loadAllEmployees')
            },

            loadData() {
                if (this.isAdmin)
                    this.loadAllPendingTransfers();
                else if (this.isManager)
                    this.loadMyPendingTransfers();
                else
                    this.loadOnlyMinePendingTransfers();

            },

            toolDBClick(event) {
                this.showToolTransferModal(event.rowData);
            },

            showToolTransferModal(selected) {
                this.selectedToolTransfer = selected;
                this.modalNewToolTransferConfirm.show();
            },

            deleteSelectedTool() {
                return;
            },

            confirmedToolTransfer() {
                this.loadAllPendingTransfers();
            },


            runAutoRefresh() {
                this.stopRefresh();
                this.refreshCount = 0;
                this.intervalRefresh = window.setInterval(() => {
                    this.refreshCount++;
                    if (this.refreshCount < 30)
                        this.loadData();
                }, 5000);
            },

            stopRefresh() {
                window.clearInterval(this.intervalRefresh)
            },

            refreshData() {
                this.loadData();
                this.runAutoRefresh();
            },

        },


        created() {
        },

        mounted() {
            this.loadData();
            this.runAutoRefresh();
            this.modalNewToolTransferConfirm = new Modal(document.getElementById('modalNewToolTransferConfirm'));
        },

        destroyed() {
            this.stopRefresh();
        }


    }
</script>

<style>

    .all-tool-transfers-grid table tr {
        cursor: pointer;
    }

    .all-tool-transfers-grid table tr .ready-action {
        background: #15ff00 !important;
        /* color: #666666 !important; */
    }



</style>
