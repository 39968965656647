<template>
    <div class="accordion" id="accordionExample">
        <div class="accordion-item" v-for="damage, index in damages" :key="damage.id">
            <h2 class="accordion-header" :id="'heading-damage-' + damage.id">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-damage-' + damage.id " aria-expanded="false" :aria-controls="'collapse-damage-' + damage.id">
                <b class="me-2">{{index + 1}}. </b> {{damage.description}}
            </button>
            </h2>
            <div :id="'collapse-damage-' + damage.id" class="accordion-collapse collapse" :aria-labelledby="'heading-damage-' + damage.id" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    
                    <div class="text-center">
                        <input type="checkbox" :id="'zoomDamageImg-' + damage.id">
                        <label :for="'zoomDamageImg-' + damage.id">
                            <img class="damage-image mb-3" v-if="damage.image_url" :src="damage.image_url" alt="">
                        </label>
                    </div>
                    
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">Oštetio: <template v-if="damage.damaged_by">{{userFullName(damage.damaged_by_obj)}}</template><template v-else>?</template></li>
                        <li class="list-group-item">Prijavio: {{userFullName(damage.reported_by_obj)}}</li>
                        <li class="list-group-item">Datum prijave: {{formatDateTime(damage.created_at)}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import UtilService from '@/service/UtilService.js';

    export default {
        props: {
            damages: {
                type: Array,
            },
        },

        methods: {
            userFullName: UtilService.userFullName,
            formatDateTime: UtilService.formatDateTime,
        }
    }
</script>

<style scoped>
    .accordion-button {
        padding: 0.6em 1.25em;
    }
    
    .damage-image {
        width: 50%;
        max-height: 500px;
    }

    input[type=checkbox] {
    display: none;
    }

    .container .damage-image {
        transition: all 0.25s ease;
        cursor: zoom-in;
    }

    input[type=checkbox]:checked ~ label > .damage-image {
        cursor: zoom-out;
        width: 100%;
        border-radius: 0;

    }
    
</style>