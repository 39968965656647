<template>
    <div class="modal fade" :id="modalId" tabindex="-1" :aria-labelledby="modalId + 'Label'" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"> {{ title }} </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <form id="tool-transfer-form" @submit.prevent="">
                        <div v-if="toolTransfer" class="container d-grid gap-2">
                            <h2>{{toolTransfer.tool_obj.name}}</h2>
                            <div class="text-center">

                                <div class="images-cover">
                                    <img class="tool-image-top mb-3" v-if="image_url" :src="image_url" alt="">
                                    <!-- <img class="tool-image-top mb-3" v-if="toolTransfer.tool_obj.image_url" :src="toolTransfer.tool_obj.image_url" alt=""> -->
                                    <silent-box v-if="toolTransfer.tool_obj.image_url" :gallery="toolImagesSBox"></silent-box>
                                </div>



                                <template v-if="toolTransfer.from_warehouse">
                                    <h2>Skladište: <strong>{{toolTransfer.from_warehouse_obj.name}}</strong></h2>
                                </template>
                                <template v-else-if="toolTransfer.from_user">
                                    <h2>Radnik: <strong>{{toolTransfer.from_user_obj.first_name}} {{toolTransfer.from_user_obj.last_name}}</strong></h2>
                                </template>
                                <template v-else>
                                    <h2>Nedodeljen alat</h2>
                                </template>

                                <h1 class="arrow-down-cricle arrow-down-cricle-success"><font-awesome-icon icon="arrow-down" /></h1>


                                <template v-if="toolTransfer.to_warehouse">
                                    <h2>Skladište: <strong>{{toolTransfer.to_warehouse_obj.name}}</strong></h2>
                                </template>
                                <template v-else-if="toolTransfer.to_user">
                                    <h2>Radnik: <strong>{{toolTransfer.to_user_obj.first_name}} {{toolTransfer.to_user_obj.last_name}}</strong></h2>
                                </template>
                            </div>

                            <hr>

                            <template v-if="tool_damages">
                                <h4 class="mb-0">Prethodna oštećenja:</h4>
                                <tool-damages-accordion :damages="tool_damages" />
                            </template>
                            <template v-else>
                                <h4>Alat nema oštećenja</h4>
                            </template>

                            <template v-if="isRequested">
                                <template v-if="isHolder">
                                    <button  @click="changeTransferToInTransfer" type="button" class="btn btn-primary btn-lg p-5">Predaj alat</button>
                                    <button @click="declineTransfer" type="button" class="btn btn-danger">Odbij transfer alata</button>
                                </template>
                                <template v-else>
                                    <!-- NOT HOLDER -->
                                    <h5 v-if="toolTransfer.from_user">{{toolTransfer.from_user_obj.first_name}} se čeka da prihvati.</h5>
                                    <h5 v-else>Čeka se na alat</h5>
                                </template>
                                <h5 v-if="toolTransfer.from_user"></h5>
                            </template>

                            <template v-if="isInTransfer">
                                <template v-if="isRecipient">
                                    <div class="btn-group" role="group" aria-label="Prihvatanje">
                                        <input v-model="transfer_accept_status" value="ACCEPT" type="radio" class="btn-check" name="accept_status" id="accept-tool" autocomplete="off" checked>
                                        <label class="btn btn-lg btn-outline-primary" for="accept-tool">Prihvatam</label>

                                        <input v-model="transfer_accept_status" value="DAMAGED" type="radio" class="btn-check" name="accept_status" id="accept-damaged-tool" autocomplete="off">
                                        <label class="btn btn-lg btn-outline-primary" for="accept-damaged-tool">Prihvatam oštećeno</label>

                                        <input v-model="transfer_accept_status" value="DECLINE" type="radio" class="btn-check" name="accept_status" id="decline-tool" autocomplete="off">
                                        <label class="btn btn-lg btn-outline-danger" for="decline-tool">Ne prihvatam</label>
                                    </div>

                                    <template v-if="transfer_accept_status === 'ACCEPT'">
                                        <button @click="confirmTransfer" type="button" class="btn btn-lg btn-primary">Prihvati alat</button>
                                    </template>

                                    <template v-if="transfer_accept_status === 'DAMAGED'">
                                        <div>
                                            <p v-if="uploading">Dodaje se</p>
                                            <div class="form-floating">
                                                <file-upload
                                                    class="form-control image-upload-form"
                                                    post-action="/upload/post"
                                                    extensions="gif,jpg,jpeg,png,webp"
                                                    accept="image/png,image/gif,image/jpeg,image/webp"
                                                    :multiple="false"
                                                    :size="1024 * 1024 * 10"
                                                    v-model="file"
                                                    @input-filter="inputFilter"
                                                    @input-file="inputFile"
                                                    inputId="upload-damaged-image"
                                                    ref="upload-damaged-image">
                                                    <font-awesome-icon size="2x" icon="camera" />
                                                    Slikajte alat
                                                </file-upload>
                                            </div>


                                        </div>

                                        <div class="mb-3 form-floating">
                                            <textarea rows="3" class="form-control text-area-floating" id="woCode" v-model="comment" placeholder="Komentar" required />
                                            <label for="woCode">Komentar (Oštećenje)</label>
                                        </div>

                                        <button @click="confirmTransfer" type="button" class="btn btn-lg btn-primary">Prihvati alat</button>
                                    </template>


                                    <template v-if="transfer_accept_status === 'DECLINE'">
                                        <button @click="declineTransfer" type="button" class="btn btn-lg btn-danger">Odbij transfer alata</button>
                                    </template>
                                </template>
                                <template v-else>
                                    <!-- NOT RECIPIENT -->
                                    <h5 v-if="toolTransfer.to_user">{{toolTransfer.to_user_obj.first_name}} se čeka da potvrdi.</h5>
                                    <h5 v-else>Čeka se da nadležni potvrdi</h5>
                                </template>
                            </template>

                            <!-- TODO: TESTIRATI KADA SE UPDATUJE TABELA JEL SE SJEBE PROP PA I MODAL -->

                            <!-- <form id="warehouse-form">
                                <div v-if="warehouse" class="form-floating">
                                    <input v-model="warehouse.name" class="form-control" type="text" placeholder="Naziv:" aria-label="Naziv skladišta alata" required>
                                    <label for="floatingInput">Naziv skladišta alata:</label>
                                    <div class="invalid-feedback">
                                        Naziv je obavezno polje.
                                    </div>
                                </div>

                            </form> -->
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Zatvori </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ToolsService from '@/service/ToolsService.js';
    import UtilService from '@/service/UtilService.js';
    import {mapState} from 'vuex'
    import AwsService from '@/service/AwsService.js'
    import ToolDamagesAccordion from '@/components/misc/tool-damages-accordion.vue';

    // import QrcodeVue from 'qrcode.vue'

    export default {

        props: {
            modalId: String,
            title: String,
            toolTransfer: Object,
            thisModal: Object
        },

        components: {
            ToolDamagesAccordion
        },
        
        data() {
            return {
                transfer_accept_status: undefined,

                comment: "",
                image_url: undefined,
                file: [],
                uploading: false,

            }
        },

        computed: {
            ...mapState([
                'toolTypes',
                'warehouses',
                'allEmployees',
                'user',
                'userAssignment'
            ]),



            toolImagesSBox() {
                let images = []
                let imageId = 1;
                if (this.toolTransfer.tool_obj.image_url){
                    let obj = {
                        src: this.toolTransfer.tool_obj?.image_url,
                        thumbnailHeight: "300px",
                        thumbnailWidth: "300px",
                        description: "Slika " + imageId++
                    }

                    images.push(obj)

                    this.toolTransfer.tool_obj?.gallery?.forEach(img => {
                        let obj = {
                            src: img.url,
                            thumbnailHeight: "300px",
                            thumbnailWidth: "300px",
                            description: "Slika " + imageId++
                        }

                        images.push(obj)
                    });
                }
                return images

            },


            employees() {
                let emp = UtilService.deepClone(this.allEmployees);
                return emp.sort((a, b) => a?.user?.first_name?.localeCompare(b?.user?.first_name));
            },

            qrData() {
                if (this.toolTransfer.tool_obj.code) return "REQUEST|" + this.toolTransfer.tool_obj.code;
                else return "REQUEST|" + this.toolTransfer.tool_obj.id;
            },

            workingHourPerm() {
                if (this.user.role === "MANAGER" || this.user.role === "ADMIN" || this.userAssignment?.pwo?.pwo_role == 1)
                    return true;
                return false;
            },

            fromType() {
                if (this.toolTransfer.from_user) return "USER"
                if (this.toolTransfer.from_warehouse) return "WAREHOUSE"
                return "NONE"
            },

            toType() {
                if (this.toolTransfer.to_warehouse) return "WAREHOUSE"
                if (this.toolTransfer.to_user) return "USER"
                return "NONE"
            },

            // toolLocation() {
            //     if (this.toolTransfer.tool_obj?.location_status === 'WORKER') return 
            // },

            modalRole() {
                if (this.user.role === 'ADMIN') return "ADMIN";
                if (this.workingHourPerm) return "MANAGER";
                if (this.toolTransfer.tool_obj?.location_status === 'WORKER' && this.toolTransfer.tool_obj.worker.id === this.user.id) return "HOLDER";
                return "REQUESTER"
            },

            isHolder() {
                if (this.toolTransfer.from_user_obj?.id === this.user.id) return true;
                if (!this.from_user && this.isManager) return true;
                return false;
            },

            isRecipient() {
                if (this.toolTransfer.to_user_obj?.id === this.user.id) return true;
                if (!this.toolTransfer.to_user && this.isManager) return true;
                return false;
            },

            isRequested() {
                if (this.toolTransfer.transfer_status === 'REQUESTED') return true;
                return false;
            },

            isInTransfer() {
                if (this.toolTransfer.transfer_status === 'IN_TRANSFER') return true;
                return false;
            },

            isAdmin() {
                if (this.user.role === 'ADMIN') return true;
                return false;
            },

            isManager() {
                if (this.user.role === "MANAGER" || this.user.role === "ADMIN" || this.userAssignment?.pwo?.pwo_role == 1)
                    return true;
                return false;
            },

            canChangeToInTransfer() {
                if (this.toolTransfer.transfer_status !== 'REQUESTED') return false;
                if (this.isHolder()) return true;

                // if (this.user.role === 'ADMIN') return true;

                return false;
            },

            tool_damages() {
                if (this.toolTransfer?.tool_obj?.damages) return this.toolTransfer.tool_obj.damages;
                return [1,23];
            }


        },

        methods: {

            validate() {
                let form = document.getElementById("tool-transfer-form");
                form.classList.add('was-validated');

                if (!form.checkValidity())
                    return false;

                return true;
            },

            submitTransfer() {
                if (!this.validate()) return

                this.createTransfer();
            },

            // prepareTransferData() {
            //     if (this.transfer_location_status === 'WORKER') {
            //         this.toolTransfer.to_warehouse = null;
            //     } else if (this.transfer_location_status === 'WAREHOUSE') {
            //         this.toolTransfer.to_user = null;
            //     }


            //     if (this.forceAccept) {
            //         this.toolTransfer.transfer_status = "ACCEPTED"
            //         this.toolTransfer.acceptedAt = UtilService.formatDatePattern(null, "YYYY-MM-DDTHH:mm:SS")
            //     }
            // },

            changeTransferToInTransfer() {

                ToolsService.toolTransferToStatusInTransfer(this.toolTransfer).then(result => {
                    console.log(result);
                    this.thisModal.hide();
                    this.$emit('confirmed');
                })
            },

            confirmTransfer() {
                if (!this.validate()) return;

                let data = {
                    "id": this.toolTransfer.id,
                    "accept_status": this.transfer_accept_status,
                    "comment": this.comment,
                    "image_url": this.image_url
                }

                ToolsService.toolTransferToStatusTransferred(data).then(result => {
                    console.log(result);
                    this.thisModal.hide();
                    this.$emit('confirmed');
                })
            },

            declineTransfer() {


                let data = {
                    "id": this.toolTransfer.id,
                    "accept_status": this.transfer_accept_status,
                }

                ToolsService.toolTransferToStatusDecline(data).then(result => {
                    console.log(result);
                    this.thisModal.hide();
                    this.$emit('confirmed');
                })
            },

            getTransferStatus() {
                if (this.toolTransfer.tool_obj?.location_status === 'WORKER' && this.toolTransfer.tool_obj.worker.id === this.user.id) return "IN_TRANSFER";
                else return "REQUESTED";

            },

            inputFilter(newFile, oldFile, prevent) {
                console.log("FILTER");
                if (newFile && !oldFile) {
                    if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
                    return prevent()
                    }
                    
                    if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
                    return prevent()
                    }
                }
            },
            
            async inputFile(newFile, oldFile) {
                if (newFile && !oldFile) {
                    console.log('add', newFile)
                    this.uploadImage();
                }
                if (newFile && oldFile) {
                    console.log('update', newFile)
                    this.uploadImage();
                }
                if (!newFile && oldFile) {
                    // remove
                    console.log('remove', oldFile)
                }
            },

            async uploadImage() {
                this.uploading = true;
                console.log(this.file);
                let imageUrl = await AwsService.uploadFile(this.file[0].file).catch(error => {
                    alert("Neuspesno dodavanje")
                    console.log(error);
                });
                this.uploading = false;
                this.image_url = imageUrl;

            },

            initData() {
                this.transfer_accept_status = undefined;
                this.comment = "";
                this.image_url = undefined;
                this.file = [];
                this.uploading = false;
            },

            setUpListener() {
                let myModalEl = document.getElementById(this.modalId)
                myModalEl.addEventListener('shown.bs.modal', () => {
                    this.initData();
                })
            }
        },

        mounted() {
            this.setUpListener();
        },

        created() {
        }

    }
</script>

<style scoped>
.v-select.form-control{
    height: 4.5em;
}

.tool-image-top {
    max-width: 100%;
    max-height: 500px;
}

.force-accept {
    font-size: 1.3em;
}

.arrow-down-cricle {
    padding: 15px 21px 12px;
    display: inline-block;
    border-radius: 100%;
}

.arrow-down-cricle-success {
    border: 1px solid green;
    background: #8cff00;
}

.text-area-floating {
    height: 8em;
}

</style>

<style>

.image-upload-form {
    height: 5em !important;
    padding: 1.5em;
}

.image-upload-form label {
    cursor: pointer;
}
</style>