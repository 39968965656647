<template>
    <div>

        <template v-if="column === 'TOOL_NAME'">
            <router-link :to="{ name: 'tool-profile', params: { toolId: data.tool_obj.id }}">
                <div class="image-cell">
                    <img v-if="data.tool_obj.image_url" :src="data.tool_obj.image_url" alt="Slika" />
                    <font-awesome-icon class="me-1" v-else size="2x" icon="image"></font-awesome-icon>

                    <span>{{data.tool_obj.name}} <strong v-if="data.tool_obj.identification">[{{data.tool_obj.identification}}]</strong></span>
                </div>
            </router-link>
        </template>

        <template v-if="column === 'FROM'">
            <template v-if="data.from_user">
                <div class="image-cell">
                    <img v-if="data.from_user_obj.avatar_url" :src="data.from_user_obj.avatar_url" alt="Slika" />
                    <div v-else class="numberCircle">{{data.from_user_obj.first_name.slice(0,1)}}</div>

                    <span>{{data.from_user_obj.first_name}} {{data.from_user_obj.last_name}}</span>
                </div>
            </template>
            <template v-else-if="data.from_warehouse">
                <div class="numberCircle">{{data.from_warehouse_obj.name.slice(0,1)}}</div>
                <span>{{data.from_warehouse_obj.name}}</span>
            </template>
        </template>

        <template v-if="column === 'TO'">
            <template v-if="data.to_user">
                <div class="image-cell">
                    <img v-if="data.to_user_obj.avatar_url" :src="data.to_user_obj.avatar_url" alt="Slika" />
                    <div v-else class="numberCircle">{{data.to_user_obj.first_name.slice(0,1)}}</div>

                    <span>{{data.to_user_obj.first_name}} {{data.to_user_obj.last_name}}</span>
                </div>
            </template>
            <template v-else-if="data.to_warehouse">
                <div class="numberCircle">{{data.to_warehouse_obj.name.slice(0,1)}}</div>
                <span>{{data.to_warehouse_obj.name}}</span>
            </template>
        </template>

        <template v-if="column === 'CMD'">

                <button type="button" class="btn btn-light" @click="$parent.$parent.showToolTransferModal(data)"><font-awesome-icon size="2x" icon="exchange-alt"></font-awesome-icon></button>

        </template>
    </div>
</template>

<script>

    export default {
        props: ['column', 'quickAction'],

        data: function() {
            return {
                data: {},
            }
        },

        computed: {
            
        },

        methods: {

        },

        mounted() {
        }

    }
</script>

<style scoped>
    .image-cell img {
        max-width: 50px;
        max-height: 50px;
        object-fit: cover;
        border-radius: 100%;
        margin-right: 1em;
        float: left;
    }

    /* .image-cell {
        word-wrap: initial;
    } */

    .numberCircle {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        padding: 8px;
        margin-right: 1em;

        background: #fff;
        border: 2px solid #666;
        color: rgb(255, 255, 255);
        background: #666;
        text-align: center;

        font: 32px Arial, sans-serif;
    }
</style>